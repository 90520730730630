






















































































































































































































































































































































import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex';
import APISTATE from '@/enums/APISTATE';
import { ClassSubjectDto } from '@/models/planning/ClassSubjectDto';
import { DepartmentDto } from '@/models/planning/DepartmentDto';
import { SchoolTermDto } from '@/models/planning/SchoolTermDto';
import { FinancedDto } from '@/models/ConstantsDto';
import { SchoolSubjectDto } from '@/models/planning/SchoolSubjectDto';
import { EducationalDirectionDto } from '@/models/planning/EducationalDirectionDto';
// import { Guid } from 'guid-typescript';

export default Vue.extend({
  name: 'classsubject-view',
  props: {
    classSubjectId: {
      type: String,
      required: false
    }
  },
  data: () => ({
    deactivating: false,
    deleting: false,
    form: {} as ClassSubjectDto,

  }),
  async mounted() {
    await Promise.all([
      this.loadDepartments(),
      this.loadSchoolTerms(),
      this.loadSchoolSubjects(),
      this.loadClassSubjects(),
      this.loadEducationalDirections(),
    ]);

    await this.loadClassSubject(this.classSubjectId);

    this.assignCriteria([
      // validation for fields that are always present
      this.criteriaRequired('relDepartment', () => this.form.relDepartment),
      this.criteriaRequired('relSchoolTerm', () => this.form.relSchoolTerm),
      { key: 'isFinanced', validation:() => [true,false].includes(this.form.isFinanced), message: "Skal udfyldes" },
      this.criteriaRequired('classId', () => this.form.classId),
      this.criteriaLength('classId', () => this.form.classId, 2, 10),
      this.criteriaRequired('relSchoolSubject', () => this.form.relSchoolSubject),
      this.criteriaRequired('subjectLevel', () => this.form.subjectLevel),
      this.criteriaLength('subjectLevel', () => this.form.classId, 1, 10),
      this.criteriaRequired('relEducationalDirection', () => this.form.relEducationalDirection),
      this.criteriaRequired('startWeek', () => this.form.startWeek),
      this.criteriaValue('startWeek', () => this.form.startWeek, 1, 53),
      this.criteriaRequired('endWeek', () => this.form.endWeek),
      this.criteriaValue('endWeek', () => this.form.endWeek, 1, 53),
      this.criteriaRequired('weeksTotal', () => this.form.weeksTotal),
      this.criteriaValue('weeksTotal', () => this.form.weeksTotal, 1, 53),
      { key: 'weeksTotal', validation:() => new RegExp(/^(\d)*(\.)?([0-9]{1})?$/).test(this.form.weeksTotal) , message: "Det må maksimalt bruges en decimal" },
      this.criteriaRequired('numberOfStudentsExpected', () => this.form.numberOfStudentsExpected),
      this.criteriaValue('numberOfStudentsExpected', () => this.form.numberOfStudentsExpected, 0, 1000),
      { key: 'budgetPlanningHours', validation:() => !!this.form.budgetPlanningHours, message: 'Skal udfyldes' },

      // validation for fields present on an existing classSubject, that is not a remote class
      { key: 'minutesPerLesson', validation:() => !!this.form.minutesPerLesson, message: 'Skal udfyldes', active:() => this.presentClassSubject },
      { key: 'subjectPlanningHoursFactor', validation:() => !!this.form.subjectPlanningHoursFactor, message: 'Skal udfyldes', active:() => this.presentClassSubject },
      { key: 'subjectPlanningHoursFactor', validation:() => (this.formSubjectPlanningHoursFactor >= 1 && this.formSubjectPlanningHoursFactor <= 100), message: 'Værdi mellem 1 og 100', active:() => this.presentClassSubject },

      // validation for fields present on an existing classSubject, that is a remote class
      { key: 'remoteStudentsToHoursFactor', validation:() => !!this.form.remoteStudentsToHoursFactor, message: 'Skal udfyldes', active:() => this.remoteClassSubject },
    ]);
  },
  methods: {
    ...mapActions('classSubjectStore', ['loadClassSubjects', 'loadClassSubject', 'createClassSubject', 'updateClassSubject', 'removeClassSubject', 'deactivateClassSubject', 'activateClassSubject']),
    ...mapActions('departments', ['loadDepartments']),
    ...mapActions('schoolSubjectsStore', ['loadSchoolSubjects']),
    ...mapActions('schoolTermStore', ['loadSchoolTerms']),
    ...mapActions('educationDirectionsStore', ['loadEducationalDirections']),
    ...mapActions('validationStore', ['assignCriteria', 'validate']),
    async create() {
      try {
        if (await this.validate()) {
          const createdId = await this.createClassSubject(this.form);
          this.$router.push(`/classSubject/${createdId}`);
        }
      }
      catch (error) {
        this.$buefy.toast.open({
          message: 'Fejl ved opret af fagtilbud.',
          type: 'is-danger'
        });
      }
    },
    async update() {
      try {
        if (await this.validate()) {
          await this.updateClassSubject(this.form);
          this.$buefy.toast.open({
            message: 'Fagtilbud opdateret',
            type: 'is-success'
          });
        }
      }
      catch (error) {
        this.$buefy.toast.open({
          message: 'Fejl ved opdatering af fagtilbud',
          type: 'is-danger'
        });
      }
    },
    async remove(controls) {
      try {
        await this.removeClassSubject(this.classSubjectId);
        controls.close();
        this.$router.push({name: 'ClassSubjects'});
      }
      catch (error) {
        this.$buefy.toast.open({
          message: 'Fejl ved slet af fagtilbud',
          type: 'is-danger'
        });
      }
    },
    async deactivate(controls) {
      try {
        await this.deactivateClassSubject(this.classSubjectId);
        controls.close();
      }
      catch (error) {
        this.$buefy.toast.open({
          message: 'Fejl ved deaktivering af fagtilbud',
          type: 'is-danger'
        });
      }
    },
    async activate() {
      try {
        await this.activateClassSubject(this.classSubjectId);
      }
      catch (error) {
        this.$buefy.toast.open({
          message: 'Fejl ved gen-aktivering af fagtilbud',
          type: 'is-danger'
        });
      }
    },
    dashUndefined(value:any) {
      return (value) ? this.$options.filters.numeral(value, '0,0.[00]') : '--';
    }

  },
  computed: {
    ...mapGetters('classSubjectStore', ['classSubject', 'classSubjects', 'classSubjectApiState', 'classSubjectMergeCandidates', 'isClassSubjectDeletable', 'classSubjectMergedClasses']),
    ...mapGetters('departments', {departments: 'departments', isLoadingDepartments: 'isLoading', isDepartmentRemote: 'isDepartmentRemote'}),
    ...mapGetters('schoolTermStore', ['schoolTerms', 'schoolTermApiState']),
    ...mapGetters('schoolSubjectsStore', ['schoolSubjects']),
    ...mapGetters('educationDirectionsStore', ['educationDirections']),
    ...mapGetters('constantsStore', ['financedOptions']),
    ...mapGetters('validationStore', ['isValid', 'validationMessage', 'criteriaRequired', 'criteriaLength', 'criteriaValue']),
    isLoading() {
      return this.isLoadingDepartments
        || this.isLoadingSchoolTerms
        || this.isLoadingClassSubjects;
    },
    isLoadingSchoolTerms() {
      return this.schoolTermApiState === APISTATE.LOADING;
    },
    isLoadingClassSubjects() {
      return this.classSubjectApiState === APISTATE.LOADING;
    },
    newItem() {
      return !this.classSubjectId;
    },
    hasMergedClasses() {
      if (this.classSubjectId) {
        return this.classSubjectMergedClasses(this.classSubjectId).length > 0;
      }
      return false;
    },
    presentClassSubject() {
      if (this.form.relDepartment) {
        return this.isDepartmentRemote(this.form.relDepartment) === false;
      }
      return false;
    },
    remoteClassSubject() {
      if (this.form.relDepartment) {
        return this.isDepartmentRemote(this.form.relDepartment) === true;
      }
      return false;
    },
    tabs() {
      if (this.newItem) {
        return [{ title: 'Stamdata', to: {name: 'NewClassSubject'}}];
      }
      return [
        { title: 'Stamdata', to: `/classSubject/${this.classSubjectId}`},
        { title: 'Deltager typer', to: `/classSubject/${this.classSubjectId}/studentTypes`}
      ];
    },
    educationalDirectionsForClassSubject() {
      return this.educationDirections('classsubject');
    },
    formRelDepartment: {
      get() {
        return this.departments.find(q => q.id === this.form.relDepartment);
      },
      set(value:DepartmentDto) {
        this.$set(this.form, "relDepartment", value.id);
      }
    },
    formRelSchoolTerm: {
      get() {
        return this.schoolTerms.find(q => q.id === this.form.relSchoolTerm);
      },
      set(value:SchoolTermDto) {
        this.$set(this.form, "relSchoolTerm", value.id);
      }
    },
    formRelMergedIntoClassSubject: {
      get() {
        let candidate = this.classSubjectMergeCandidates(this.form).find(q => q.id === this.form.relMergedIntoClassSubject);
        if (!candidate) { // Default to id:null if no candidate found
          candidate = this.classSubjectMergeCandidates(this.form).find(q => q.id === null);
        }
        return candidate;
      },
      set(value:ClassSubjectDto) {
        this.$set(this.form, 'relMergedIntoClassSubject', value?.id);
      }
    },
    formIsFinanced: {
      get() {
        return this.financedOptions.find(q => q.value === this.form.isFinanced);
      },
      set(value:FinancedDto) {
        this.$set(this.form, "isFinanced", value.value);
      }
    },
    formRelSchoolSubject: {
      get() {
        return this.schoolSubjects.find(q => q.id === this.form.relSchoolSubject);
      },
      set(value:SchoolSubjectDto) {
        this.$set(this.form, 'relSchoolSubject', value.id);
      }
    },
    formRelEducationalDirection: {
      get() {
        return this.educationDirections('classsubject').find(q => q.id === this.form.relEducationalDirection);
      },
      set(value:EducationalDirectionDto) {
        this.$set(this.form, 'relEducationalDirection', value.id);
      }
    },
    formSubjectPlanningHoursFactor: {
      get() {
        return Math.round((this.form.subjectPlanningHoursFactor ?? 0) * 100);
      },
      set(value:number) {
        this.$set(this.form, 'subjectPlanningHoursFactor', value / 100);
      }
    },
    calcHoursForPlanning: {
      get() {
        let result = undefined;
        if (this.remoteClassSubject) {
          result = this.form.numberOfStudentsExpected * this.form.remoteStudentsToHoursFactor;
        }
        else if (this.presentClassSubject) {
          result = this.form.budgetPlanningHours * this.form.subjectPlanningHoursFactor;
        }
        return isNaN(result) ? undefined : result;
      }
    },
    calcReportedHoursForPlanning: {
      get() {
        let result = this.form.numberOfStudentsReported * this.form.remoteStudentsToHoursFactor;
        return isNaN(result) ? undefined : result;
      }
    },
    calcLessons: {
      get() {
        let result = this.form.budgetPlanningHours * 60 / this.form.minutesPerLesson;
        return isNaN(result) ? undefined : result;
      }
    },
    calcLessonsPerWeek: {
      get() {
        let result = this.calcLessons / this.form.weeksTotal;
        return isNaN(result) ? undefined : result;
      }
    }


  },
  watch: {
    'classSubject'(newValue) {
      this.form = {...newValue};

      // Set default value for isFinanced
      if (!Object.prototype.hasOwnProperty.call(this.form, 'isFinanced')) {
        this.form.isFinanced = true;
      }
    }
  }
})
